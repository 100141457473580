import { useState } from 'react'

export const useRegister = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const register = async (email, username, password, password_confirm, farm_id, farm_token) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const response = await fetch('/api/user/register', {
            method: 'POST', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email, username, password, password_confirm, farm_id, farm_token})
        })

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            return false
        }
        if (response.ok) {
            localStorage.setItem('user', JSON.stringify(json));

            setIsLoading(false);
            setSuccess("Account Registered");
            return true
        }
    }

    return { register , isLoading, error, success}
}