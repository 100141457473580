import './MapIcons.css'

import MapIconAdd from '../components/MapIconAdd'
import MapIconUpdate from '../components/MapIconUpdate'
import MapIconRemove from '../components/MapRemove'

export default function Subscription() {
    return (
        <div className="subscription-page">
            <MapIconAdd />
            <MapIconUpdate />
            <MapIconRemove />
        </div>
    )
}