import "./Landing.css"
import { useLogout } from '../../hooks/useLogout'

export default function Landing(params) {
    const { userData, setSelect } = params;

    const {logout} = useLogout();

    // logout users
    const handleLogout = () => {
        logout();
    }

    return(
        <>
        <div className="user-info">
            <div className="profile">{userData && userData.initials}</div>
            <div className="username">{userData && userData.username}</div>
            <div className="email">{userData && userData.email}</div>
            <div className="expiration">Subscription Expires: 24/02/2024</div>
        </div>
        <div className="options">
            <button className="nav-button" onClick={()=>setSelect('farms')}><div className="farm-icon" /><div>Manage Farms</div></button>
        </div>
        <button onClick={handleLogout} className="logout-button">Log Out</button>
        </>
    )
}