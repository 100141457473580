import './RemoveFarmPopup.css'

import { useAuthContext } from '../../hooks/useAuthContext';

export default function Popup(props) {
    const { farmId, farmName, setError } = props;
    const { user } = useAuthContext();

    const handleClick = async (e) => {
        e.preventDefault()

        const response = await fetch('/api/user/remove-farm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ user.token }`
            },
            body: JSON.stringify({
                farm_id: farmId,
            })
        })

        const json = await response.json();

        if (!response.ok) { setError(json.error) }
        if (response.ok) { setError(null) }
        
        props.setTrigger(false)
    }
    
    return (
        <>
        <div className="message-container">
            <p>
                Are you sure that you would like to remove <b>{farmName}</b> from your profile.
            </p>
        </div>
        
        <button className='confirm-button' onClick={handleClick}>Confirm</button>
        </>
    );
}