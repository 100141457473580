import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function DeviceAdd() {
    const { user } = useAuthContext();

    const [farms, setFarms] = useState('');

    const [farmId, setFarmId] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [scanningInterval, setScanningInterval] = useState('');
    const [nextConnect, setNextConnection] = useState('');

    const [ipv4, setIpv4] = useState('');
    const [port, setPort] = useState('');

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/admin/get-farms', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setFarms(json);
            }
        }

        if (user) {
            fetchFarms()
        }
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/add-devices', {
            method: 'POST',
            body: JSON.stringify({
                'type': deviceType,
                'scanning_interval': scanningInterval,
                'next_connection': nextConnect,
                'parent_id': farmId,
                'ipv4': ipv4,
                'port': port
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Device Added")
        }
        setIsLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>Add Device</h1>
            <label>Farm</label>
            <select 
                id='farmSelect' 
                onChange={(e) => setFarmId(e.target.value)} 
            >
                <option value="">--Select Farm--</option>
                {farms && farms.map(farm => {
                    return <option key={farm._id} value={farm._id}>{farm.name}</option>
                })}
            </select>
            <label>Device Type</label>
            <select 
                id='typeSelect' 
                onChange={(e) => setDeviceType(e.target.value)} 
            >
                <option value="">--Select Device Type--</option>
                <option value='breadcrumb'>BreadCrumbs</option>
            </select>
            <label>Scanning Interval</label>
            <input 
                id='scanningInterval'
                type='number' 
                onChange={(e) => setScanningInterval(e.target.value)} 
            />
            <label>Next Connection</label>
            <input 
                id='nextConnection'
                type='datetime-local' 
                onChange={(e) => setNextConnection(e.target.value)} 
            />

            {(deviceType === 'breadcrumb') ? 
                <>
                    <label>IP address</label>
                    <input 
                        id='ipv4'
                        type='text' 
                        onChange={(e) => setIpv4(e.target.value)} 
                    />
                    <label>Port</label>
                    <input 
                        id='port'
                        type='number' 
                        onChange={(e) => setPort(e.target.value)} 
                    />
                </>
                : ''}

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Add Device</button>
        </form>
    )
}