import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function SubscriptionRemove() {
    const [subscriptionId, setSubscriptionId] = useState('');

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { user } = useAuthContext();

    const [subscriptions, setSubscriptions] = useState('');

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const response = await fetch('/api/admin/get-subscriptions', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setSubscriptions(json);
            }
        }

        if (user) {
            fetchSubscriptions()
        }
    }, [user])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/remove-subscriptions', {
            method: 'POST',
            body: JSON.stringify({
                '_id': subscriptionId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Subscription Removed")
        }
        setIsLoading(false)
    }
    return (
        <form onSubmit={handleSubmit}>
            <h1>Remove Subscription</h1>
            <label>Name</label>
            <label>Subscription</label>
            <select 
                id='subscriptionSelect' 
                onChange={(e) => setSubscriptionId(e.target.value)} 
            >
                <option value="">--Select Subscription--</option>
                {subscriptions && subscriptions.map(subscription => {
                    return <option key={subscription._id} value={subscription._id}>{subscription.name}</option>
                })}
            </select>

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Remove Subscription</button>
        </form>
    )
}