import './Navbar.css'

import { useState } from 'react'

import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext';

import ProfilePopup from './ProfilePopup'
import Popup from './Popup'

export default function Navbar() {
    const {user} = useAuthContext();

    // get initials fro username
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [...user.username.matchAll(rgx)] || [];
    initials = (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    const [navbarOpen, setNavbarOpen] = useState(false)

    // expand/collapse bar
    const handleNavToggle = () => {
        setNavbarOpen(!navbarOpen)
    }
    const closeNavMenu = () => {
        setNavbarOpen(false)
    }

    const [profilePopup, setProfilePopup] = useState(false)

    return (
        <>
        <div className={`${navbarOpen ? "navbar show-menu" : "navbar "}`}>
            <button className={`${navbarOpen ? "logo show-menu" : "logo"}`} onClick={handleNavToggle}></button>
            <div className='nav-buttons'>
                <ul className={`${navbarOpen ? "show-menu" : ""}`}>
                    <li onClick={() => closeNavMenu()}>
                        <Link to='/'><div className='home'/></Link>
                    </li>
                    {/* <li onClick={() => closeNavMenu()}>
                        <Link to='/breadcrumbs'><div className='breadcrumb'/></Link>
                    </li> */}
                </ul>
                {user.role > 1 ? "" : <>
                    <div className={`${navbarOpen ? "show-menu divider" : "divider"}`}> </div>
                    <ul className={`${navbarOpen ? "show-menu" : ""}`}>
                        <li onClick={() => closeNavMenu()}>
                            <Link to='/admin/subscriptions'><div className='subscription'/></Link>
                        </li>
                        <li onClick={() => closeNavMenu()}>
                            <Link to='/admin/farms'><div className='farm'/></Link>
                        </li>
                        <li onClick={() => closeNavMenu()}>
                            <Link to='/admin/devices'><div className='device'/></Link>
                        </li>
                        <li onClick={() => closeNavMenu()}>
                            <Link to='/admin/mapicons'><div className='map-icon'/></Link>
                        </li>
                    </ul></>}    
            </div>
            <button onClick={() => setProfilePopup(true)} className={`${navbarOpen ? "profile-button show-menu" : "profile-button"}`}>
                {initials}
            </button>
        </ div>
        <Popup trigger={profilePopup} setTrigger={setProfilePopup}>
            <ProfilePopup />
        </Popup>
        </>
    )
}