import "./ProfilePopup.css"

import { 
    useEffect,
    useState,
} from "react";


import { useAuthContext } from '../hooks/useAuthContext';

import Farms from './profilePopupViews/Farms';
import Landing from './profilePopupViews/Landing'

export default function ProfilePopup(params) {
    
    const {user} = useAuthContext();
    const [userData, setUserData] = useState(null); 

    const [select, setSelect] = useState("landing");

    // get user data on open
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('/api/user', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const json = await response.json();

            if (response.ok) {
                // get initials fro username
                let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
                let initials = [...user.username.matchAll(rgx)] || [];
                initials = (
                    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
                ).toUpperCase();

                json.initials = initials

                setUserData(json);
            }
        };

        if (user) {
            fetchUser();
        }
    }, [user])

    return (
        <div className="settings-popup">
            {(() => {
                switch (select) {
                    case "landing":
                        return <Landing userData={userData} setSelect={setSelect} />
                    case "farms": 
                        return <Farms userData={userData} setSelect={setSelect} />
                    default:
                        return <Landing userData={userData} />
            }})()}
        </div>
    )
}