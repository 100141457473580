import MapPopup from './MapPopup'

import { 
    Marker, Popup,
} from 'react-leaflet'

import {
    useState
} from 'react'

import L from 'leaflet'

export default function MapIcon2(props) {
    const {iconData, displayDevice, displayFarm, icon, iconFault} = props;

    const [markerSelected, setMarkerSelected] = useState(false);

    const customMarker = new L.Icon({
        iconUrl: icon,
        iconRetinaUrl: icon,
        popupAnchor:  [-0, -10],
        iconSize: [30,30], 
    })

    const customMarkerFault = new L.Icon({
        iconUrl: iconFault,
        iconRetinaUrl: iconFault,
        popupAnchor:  [-0, -10],
        iconSize: [30,30], 
    })

    return (displayDevice && displayFarm) ? (
        iconData.connection_status ? 
            <Marker 
                icon={customMarker} 
                position={[iconData.location.latitude, iconData.location.longitude]} 
                eventHandlers={{
                    popupclose: () => {
                        setMarkerSelected(false)
                    },
                    popupopen: () => {
                        setMarkerSelected(true)
                    }
                }}
            >
                <MapPopup 
                    markerSelected={markerSelected} 
                    device_id={iconData.device_id}
                    location={iconData.location}
                />
            </Marker> :
            <Marker 
                icon={customMarkerFault} 
                position={[iconData.location.latitude, iconData.location.longitude]} 
                eventHandlers={{
                    popupclose: () => {
                        setMarkerSelected(false)
                    },
                    popupopen: () => {
                        setMarkerSelected(true)
                    }
                }}
            >
                <Popup>Unable to connect to device_id: {iconData.device_id}</Popup>
            </ Marker>
    ) : "";
}