import './Login.css'

import { Link, useParams } from 'react-router-dom'

import { useState, } from 'react'

export default function PasswordReset(params) {
    const {id} = useParams()

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const [newPassword, setNewPassword] = useState();
    const [newPasswordConfirm, setNewPasswordConfirm] = useState();

    // post new password 
    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const response = await fetch('/api/user/reset-password', {
            method: 'POST', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                token: id,
                new_password: newPassword,
                new_password_confirm: newPasswordConfirm
            })
        })

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            console.log(json.error)
        }
        if (response.ok) {
            setIsLoading(false);
            setSuccess(json.message);
        }
    }

    return (
        <div className='login-page'>
            <div className='form-container'>
                <div className='information-container'>
                    <div className='company-logo'/>
                    <div className='company-name'>PAStech <span className='accent'>Rural</span></div>
                    <div className='description'>
                        Description about the application here
                    </div>
                    <a href='https://pastech.com.au/contact/' className='contact-button'>
                        Contact Us
                    </a>
                </div>
                <div className='entry-container'>
                    <div className='switch-message'>
                    Already have an account? <Link className='link' to='/login'>Log In</Link>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='welcome-message'>
                            <div className='text1'>Reset Password</div>
                            <div className='text2'>Enter new password below.</div>
                        </div>
                        
                        <input 
                            type='password'
                            id='password'
                            placeholder="New Password" 
                            onChange={(e) => setNewPassword(e.target.value)} 
                        />
                        <input 
                            type='password'
                            id='password'
                            placeholder="New Password Confirm" 
                            onChange={(e) => setNewPasswordConfirm(e.target.value)} 
                        />

                        {error && <div className='error-message'>{error}</div>}
                        {success && <div className='error-message'>{success}</div>}

                        <div className='buttons'>
                            <button className='submit-button' disabled={isLoading}>Reset Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}