import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function SubscriptionUpdate() {
    const [subscriptionId, setSubscriptionId] = useState('');
    const [name, setName] = useState("")
    const [expirationDate, setExpirationDate] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { user } = useAuthContext();

    const [subscriptions, setSubscriptions] = useState('');

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const response = await fetch('/api/admin/get-subscriptions', {
                method: 'POST',
                body: JSON.stringify(),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setSubscriptions(json);
            }
        }

        if (user) {
            fetchSubscriptions()
        }
    }, [user])

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const response = await fetch('/api/admin/get-subscriptions', {
                method: 'POST',
                body: JSON.stringify({
                    '_id': subscriptionId,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setName(json[0].name)
                setExpirationDate(new Date(json[0].expiration_date).toISOString().split('T')[0])
                setPhone(json[0].phone)
                setEmail(json[0].email)
            }
        }

        if (user && subscriptionId) {
            fetchSubscriptions()
        }
    }, [user, subscriptionId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/update-subscriptions', {
            method: 'POST',
            body: JSON.stringify({
                '_id': subscriptionId,
                'name': name, 
                'expiration_date':expirationDate, 
                'phone': phone,
                'email': email
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Subscription Updated")
        }
        setIsLoading(false)
    }
    return (
        <form onSubmit={handleSubmit}>
            <h1>Update Subscription</h1>
            <label>Name</label>
            <label>Select Subscription</label>
            <select 
                id='subscriptionSelect' 
                onChange={(e) => setSubscriptionId(e.target.value)} 
            >
                <option value="">--Select Subscription--</option>
                {subscriptions && subscriptions.map(subscription => {
                    return <option key={subscription._id} value={subscription._id}>{subscription.name}</option>
                })}
            </select>
            <label>New Name</label>
            <input 
                type='text' 
                id="name" 
                defaultValue={name}
                disabled={!subscriptionId}
                onChange={(e) => setName(e.target.value)}  
            />
            <label>New Expiration Date</label>
            <input 
                type='date' 
                id="expiration_date"
                defaultValue={expirationDate}
                disabled={!subscriptionId}
                onChange={(e) => setExpirationDate(e.target.value)} 
            />
            <label>New Phone</label>
            <input 
                type='tel'
                id="phone"
                defaultValue={phone}
                disabled={!subscriptionId}
                onChange={(e) => setPhone(e.target.value)} 
            />
            <label>New Email</label>
            <input 
                type='email' 
                id="email"
                defaultValue={email}
                disabled={!subscriptionId}
                onChange={(e) => setEmail(e.target.value)} 
            />

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Update Subscription</button>
        </form>
    )
}