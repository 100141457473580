import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function FarmUpdate() {
    const { user } = useAuthContext();

    const [subscriptions, setSubscriptions] = useState('');
    const [farms, setFarms] = useState('');

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [subscriptionId, setSubscriptionId] = useState('');
    const [name, setName] = useState('');
    const [farmId, setFarmId] = useState('');
    const [longitude, setLongitude] = useState('');
    const [latitude, setLatitude] = useState('');

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const response = await fetch('/api/admin/get-subscriptions', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setSubscriptions(json);
            }
        }

        if (user) {
            fetchSubscriptions()
        }
    }, [user])

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/admin/get-farms', {
                method: 'POST',
                body: JSON.stringify({
                   'subscription_id': subscriptionId 
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                setFarms([{'_id': json.error}])
                console.log(json.error)
            } if (response.ok) {
                setFarms(json);
            }
        }

        if (user && subscriptionId) {
            fetchFarms()
        }
    }, [user, subscriptionId])

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/admin/get-farms', {
                method: 'POST',
                body: JSON.stringify({
                   '_id': farmId 
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                setFarms([{'_id': json.error}])
                console.log(json.error)
            } if (response.ok) {
                setName(json[0].name);
                setLongitude(json[0].location.longitude);
                setLatitude(json[0].location.latitude);
            }
        }

        if (user && farmId) {
            fetchFarms()
        }
    }, [user, farmId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/update-farms', {
            method: 'POST',
            body: JSON.stringify({
                '_id': farmId,
                'name': name,
                'location': {'longitude': longitude, 'latitude': latitude},
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Farm Updated")
        }
        setIsLoading(false)
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <h1>Update Farm</h1>
            <label>Subscription</label>
            <select 
                id='subscriptionSelect' 
                onChange={(e) => setSubscriptionId(e.target.value)} 
            >
                <option value="">--Select Subscription--</option>
                {subscriptions && subscriptions.map(subscription => {
                    return <option key={subscription._id} value={subscription._id}>{subscription.name}</option>
                })}
            </select>
            <label>Name</label>
            <select 
                id='farmSelect' 
                onChange={(e) => setFarmId(e.target.value)} 
                disabled={!subscriptionId}
            >
                <option value="">--Select Farm--</option>
                {farms && farms.map(farm => {
                    return <option key={farm._id} value={farm._id}>{farm.name}</option>
                })}
            </select>
            <label>Name</label>
            <input 
                id='name' 
                type='text'
                disabled={!farmId} 
                defaultValue={name}
                onChange={(e) => setName(e.target.value)} 
            />
            <input 
                id='latitude' 
                type='text' 
                disabled={!farmId} 
                placeholder="Latitude" 
                defaultValue={latitude}
                onChange={(e) => setLatitude(e.target.value)}
            />
            <input 
                id='longitude' 
                text='text' 
                disabled={!farmId} 
                placeholder="Longitude" 
                defaultValue={longitude} 
                onChange={(e) => setLongitude(e.target.value)} 
            />

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Update Farm</button>
        </form>
    )
}