import "./Farms.css"

import { useState, useEffect } from 'react'

import AddFarm from './AddFarm'
import Popup from '../Popup'
import RemoveFarmPopup from './RemoveFarmPopup'

import { useAuthContext } from '../../hooks/useAuthContext';

export default function Farms(params) {
    const {user} = useAuthContext();

    const { userData, setSelect } = params;
    const [farmSelect, setFarmSelect] = useState(null);
    const [farms, setFarms] = useState("");

    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [farmName, setFarmName] = useState("");
    const [farmId, setFarmId] = useState("");

    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/farm/s', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const json = await response.json();

            if (response.ok) {
                setFarms(json);
            }
        };

        if (user && !farmSelect) {
            fetchFarms();
        }
    }, [user, confirmationPopup, farmSelect])

    return(
        <>
        <div className="user-info">
            <div className="username">{userData && userData.username}</div> 
        </div>
        {(() => {
            switch (farmSelect) {
                case "add-farm":
                    return <AddFarm setFarmSelect={setFarmSelect}/>
                default: 
                    return (
                        <>
                        <div className="farm-list">
                            {farms && farms.map((farm) => {
                                return (
                                    <div className="farm">{farm.name}
                                        <div 
                                            className='delete-button' 
                                            onClick={() => {
                                                setFarmName(farm.name)
                                                setFarmId(farm._id)
                                                setConfirmationPopup(true)
                                            }}
                                        >
                                            <div className="x" />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {error && <div className='farms error-message'>{error}</div>}
                        <button className="add-farm" onClick={()=>setFarmSelect('add-farm')}>Add Farms</button>
                        <div className="back-button" onClick={()=>setSelect('landing')}><div className="img" /></div>

                        <Popup trigger={confirmationPopup} setTrigger={setConfirmationPopup}>
                            <RemoveFarmPopup 
                                setTrigger={setConfirmationPopup} 
                                farmName={farmName} 
                                farmId={farmId} 
                                setError={setError}
                            />
                        </Popup>
                        </>
                    )
            }
        })()}
        </>
    )
}