import "./LayersControl.css"

import {useState, useEffect} from 'react'

import { useAuthContext } from '../hooks/useAuthContext';

export default function LayersControl(props) {
    const {iconStates, setIconStates, farmStates, setFarmStates} = props;

    const {user} = useAuthContext();

    const [farms, setFarms] = useState("");

    // get user data on open
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('/api/farm/s', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const json = await response.json();

            if (response.ok) {
                setFarms(json.reduce((acc, obj) => {
                    const key = obj["_id"];
                
                    return { ...acc, [key]: {name: obj.name} };
                }, {}));
            }
        };

        if (user) {
            fetchUser();
        }
    }, [user])

    return ((iconStates && farmStates) ?
        <div className="layer-control-container">
            {iconStates && Object.keys(iconStates).map(key => {
                return (<div className="selection controls">
                    <input 
                        type="checkbox" 
                        id={iconStates[key].id} 
                        key={iconStates[key].id} 
                        name="devices" 
                        value={iconStates[key].id} 
                        checked={iconStates[key].checked} 
                        onChange={(e) => setIconStates(
                            {...iconStates, [e.target.value]: {...iconStates[e.target.value], checked: !iconStates[e.target.value].checked}}
                        )}
                    />
                    <label>{iconStates[key].icon_type}</label>
                </div>)
            })}
            <hr className="rounded controls"/>
            {farmStates && Object.keys(farmStates).map(key => {
                return (<div className="selection controls">
                    <input 
                        type="checkbox" 
                        id={farmStates[key].id} 
                        key={farmStates[key].id} 
                        name="farms" 
                        value={farmStates[key].id} 
                        checked={farmStates[key].checked} 
                        onChange={(e) => setFarmStates(
                            {...farmStates, [e.target.value]: {...farmStates[e.target.value], checked: !farmStates[e.target.value].checked}}
                        )}
                    />
                    <label>{farms[key].name}</label>
                </div>)
            })}
            <div className="layer-icon" />
        </div> : ""
    )
}