import "./AddFarm.css"

import { useState } from 'react'

import { useAuthContext } from '../../hooks/useAuthContext';

export default function AddFarm(params) {
    const { setFarmSelect } = params
    const { user } = useAuthContext();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const [farmID, setFarmID] = useState('');
    const [farmToken, setFarmToken] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const response = await fetch('/api/user/add-farm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${ user.token }`
            },
            body: JSON.stringify({
                farm_id: farmID,
                farm_token: farmToken
            })
        })

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
            setSuccess(json.message)
        }
    }

    return (
        <>
        <div className='add-farm-container'>
            <form onSubmit={handleSubmit}>
                <input 
                    type="text"
                    id='number'
                    onChange={(e) => setFarmID(e.target.value)} 
                    value={farmID}
                    placeholder='Farm ID'
                />
                <input 
                    type="password"
                    id="token"
                    onChange={(e) => setFarmToken(e.target.value)}
                    value={farmToken}
                    placeholder="Farm Token"
                />

                {error && <div className='error-message'>{error}</div>}
                {success && <div className='success-message'>{success}</div>}

                <button disabled={isLoading}>Add</button>
            </form>
        </div>
        
        <div className="back-button" onClick={()=>setFarmSelect(null)}><div className="img" /></div>
        </>
    )
}