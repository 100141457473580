import './App.css';

import {
  Route,
  Routes,
  Navigate
} from 'react-router-dom'

import { useEffect } from 'react'

import jwt_decode from 'jwt-decode';

import { useAuthContext } from './hooks/useAuthContext';
import { useLogout } from "./hooks/useLogout";

import Navbar from './components/Navbar'

import Login from './pages/Login'
import Register from './pages/Register';
import Validate from './pages/Validate';
import ResendValidate from './pages/ResendValidate';
import SendPasswordReset from './pages/SendPasswordReset';
import PasswordReset from './pages/PasswordReset';
import Map from './pages/Map';

import Subscription from './admin/pages/Subscription';
import Farms from './admin/pages/Farms';
import Devices from './admin/pages/Devices';
import MapIcons from './admin/pages/MapIcons';

function App() {
  const { user } = useAuthContext();
  const { logout } = useLogout();

  useEffect(() => {
    const checkSession = async () => {
      const decodedToken = jwt_decode(user.token);
  
      // calculate number of milliseconds left + buffer
      const timeLeft = decodedToken.exp*1000+10000 - new Date().getTime()

      if (timeLeft <= 0) { // if expired logout
        logout();
      } else { // set timeout to logout at expiration
        setTimeout(() => {
          logout();
        }, (timeLeft))
      }
    }

    if (user) {
      checkSession();
    }
  }, [logout, user])

  return (
    <>
    <Routes>
      <Route path='/' element={
        user ? <div className='page'> <Navbar /><Map /> </div> : <Navigate to='/login' />
      } />
      {/* <Route path='/breadcrumbs' element={
        user ? <div className='page'> <Navbar /><div>BreadCrumbs Page</div> </div> : <Navigate to='/login' />
      } /> */}
      <Route path='/admin/subscriptions' element={
        user ? <div className='page'> <Navbar /><div><Subscription /></div> </div> : <Navigate to='/login' />
      } />
      <Route path='/admin/farms' element={
        user ? <div className='page'> <Navbar /><div><Farms /></div> </div> : <Navigate to='/login' />
      } />
      <Route path='/admin/devices' element={
        user ? <div className='page'> <Navbar /><div><Devices /></div> </div> : <Navigate to='/login' />
      } />
      <Route path='/admin/mapicons' element={
        user ? <div className='page'> <Navbar /><div><MapIcons /></div> </div> : <Navigate to='/login' />
      } />
      <Route path='/login' element={
        !user ? <Login /> : <Navigate to='/' />
      }/>
      <Route path='/register' element={
        !user ? <Register />: <Navigate to='/' />
      }/>
      <Route path={'/validate?/:id'} element={
        !user ? <Validate /> : <Navigate to='/' />
      }/>
      <Route path={'/resend-validate'} element={
        !user ? <ResendValidate /> : <Navigate to='/' />
      }/>
      <Route path={'/send-password-reset'} element={
        !user ? <SendPasswordReset /> : <Navigate to='/' />
      }/>
      <Route path={'/password-reset/:id'} element={
        !user ? <PasswordReset /> : <Navigate to='/' />
      }/>
    </Routes>
    </>
  );
}

export default App;
