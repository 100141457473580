import { useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function SubscriptionAdd() {
    const [name, setName] = useState("")
    const [expirationDate, setExpirationDate] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { user } = useAuthContext();

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/add-subscriptions', {
            method: 'POST',
            body: JSON.stringify({
                'name': name, 
                'expiration_date':expirationDate, 
                'phone': phone,
                'email': email
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Subscription Added")
        }
        setIsLoading(false)
    }
    return (
        <form onSubmit={handleSubmit}>
            <h1>Add Subscription</h1>
            <label>Name</label>
            <input 
                type='text' 
                id="name" 
                onChange={(e) => setName(e.target.value)}  
            />
            <label>Expiration Date</label>
            <input 
                type='date' 
                id="expiration_date"
                onChange={(e) => setExpirationDate(e.target.value)} 
            />
            <label>Phone</label>
            <input 
                type='tel'
                id="phone"
                onChange={(e) => setPhone(e.target.value)} 
            />
            <label>Email</label>
            <input 
                type='email' 
                id="email"
                onChange={(e) => setEmail(e.target.value)} 
            />

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Add Subscription</button>
        </form>
    )
}