import './MapPopup.css';

import io  from 'socket.io-client'

import {
    Popup,
} from 'react-leaflet'

import {
    useState,
    useEffect,
} from 'react'

import { useAuthContext } from '../hooks/useAuthContext';

export default function MapPopup(props) {    
    const {markerSelected, device_id, location} = props;

    const [deviceData, setDeviceData] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);
    const [deviceExists, setDeviceExists] = useState(false);

    const {user} = useAuthContext();

    useEffect(() => {
        if (user && markerSelected) {
            const dataSocket = io("/breadcrumb", {
                path: '/api/live-data',
                query: { hist_limit: 1, device_id: device_id},
                auth: { authorization: `Bearer ${user.token}` },
                forceNew: true
            })

            dataSocket.on("connect", () => {
                console.log(`socket connected - device data for ${device_id}`)
                
            });

            dataSocket.on('disconnect', () => {
                console.log(`socket disconnected - device data for ${device_id}`)
                setSocketConnected(false);
            })

            dataSocket.on("data", (data) => {
                setSocketConnected(true);
                setDeviceData(data);
            });

            dataSocket.on("connect_error", (error) => {
                console.log(`socket error - ${error.message}`)
                setSocketConnected(false);
            });

            return () => {
                dataSocket.disconnect();

                dataSocket.off("connect");
                dataSocket.off("disconnect");
                dataSocket.off("data");
                dataSocket.off("connect_error");
            }
        }
    }, [user, markerSelected, device_id])

    useEffect(() => {
        const fetchDevice = async () => {
            const response = await fetch('/api/device', {
                method: 'POST', 
                headers: { 
                    'Authorization': `Bearer ${user.token}`, 
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ _id: device_id })
            });
            const json = await response.json();

            if (response.ok) {
                if (json.length === 0) {
                    setDeviceExists(false);
                }
                else {
                    setDeviceExists(true);
                }
            } else {
                console.log(json.error)
            }
        };

        if (user && markerSelected) {
            fetchDevice();
        }
    }, [user, markerSelected, device_id])

    return (
        <Popup>
            {(!socketConnected) ? 
            <div>
                Loading...
            </div> : 
            (!deviceExists) ?
            <div>
                Device Linked to Marker does not exist.
            </div> :
            (socketConnected && deviceData) ?
            <div className='popup-container'>
                <div className='title'>{deviceData[0].name}</div>
                <div className='date-time'>{(new Date(deviceData[0].timestamp)).toLocaleString()}</div>
                <div className='ip-address'>{deviceData[0].ipv4}</div>
                <div className='data-container'>
                    <div className='item'>
                        <div className='field'>
                            # Peers
                        </div>
                        <div className='value'>
                            {deviceData[0].peer.length}
                        </div>
                    </div>
                    <div className='item'>
                        <div className='field'>
                            Device Temp
                        </div>
                        <div className='value'>
                            {deviceData[0].temperature}<span className='unit-alt'>&#176;</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='field'>
                            Scan Int
                        </div>
                        <div className='value'>
                            {deviceData[0].scanning_interval}<span className='unit'>sec</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='field'>
                            Latitude
                        </div>
                        <div className='value'>
                            {location.latitude.toFixed(3)}<span className='unit-alt'>&#176;</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='field'>
                            Longitude
                        </div>
                        <div className='value'>
                            {location.longitude.toFixed(3)}<span className='unit-alt'>&#176;</span>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='field'>
                            Battery
                        </div>
                        <div className='value'>
                            {deviceData[0].batt_voltage.toFixed(2)}<span className='unit'>v</span>
                        </div>
                    </div>
                </div>
            </div> :
            <div>
                <div>No Device Data Available for {device_id}!</div>
                <p>
                    If issue persisits try the following steps
                    <li type="1">Check the status of your PAStech device scanner</li>
                    <li type="1">Switch of your device scanner for 5 secons and then tunr it on again</li>
                    <li type="1">If issues still persisit contact PAStech</li>
                </p>
            </div>} 
        </Popup>
    )
}