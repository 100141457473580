import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function MapIconRemove() {
    const { user } = useAuthContext();

    const [farms, setFarms] = useState('');
    const [devices, setDevices] = useState('');

    const [farmId, setFarmId] = useState('');
    const [iconType, setIconType] = useState('');
    const [deviceId, setDeviceId] = useState('');

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/admin/get-farms', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setFarms(json);
            }
        }

        if (user) {
            fetchFarms()
        }
    }, [user])

    useEffect(() => {
        const fetchDevices = async () => {
            const response = await fetch('/api/admin/get-devices', {
                method: 'POST',
                body: JSON.stringify({
                    'parent_id': farmId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
    
            if (!response.ok) {
                setDevices([{'_id': json.error}])
                console.log(json.error)
            } if (response.ok) {
                setDevices(json)
            }
        }

        if (user && farmId) {
            fetchDevices()
        }
    }, [user, farmId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/remove-mapicons', {
            method: 'POST',
            body: JSON.stringify({
                'icon_type': iconType,
                'device_id': deviceId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Device Added")
        }
        setIsLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>Remove Map Icon</h1>
            <label>Farm</label>
            <select 
                id='farmSelect' 
                onChange={(e) => setFarmId(e.target.value)} 
            >
                <option value="">--Select Farm--</option>
                {farms && farms.map(farm => {
                    return <option key={farm._id} value={farm._id}>{farm.name}</option>
                })}
            </select>
            <label>Device</label>
            <select disabled={!farmId} id='typeSelect' onChange={(e) => setDeviceId(e.target.value)}>
                <option value="">--Select Device--</option>
                {devices && devices.map(device => {
                    if(device.type === 'breadcrumb') {
                        return <option key={device._id} value={device._id}>{device.type} - {device.ipv4}</option>
                    } else {
                        return <option key={device._id} value={device._id}>{device.type} - {device._id}</option>
                    }
                })}
            </select>

            <label>Icon Type</label>
            <select 
                id='typeSelect' 
                onChange={(e) => setIconType(e.target.value)} 
                value={iconType}
            >
                <option value="">--Select Icon Type--</option>
                <option value='vehicle'>Vehicle</option>
                <option value='structure_node'>Structure Nodes</option>
            </select>

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Remove Icon</button>
        </form>
    )
}