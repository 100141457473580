import './Farms.css'

import FarmAdd from '../components/FarmAdd'
import FarmUpdate from '../components/FarmUpdate'
import FarmRemove from '../components/FarmRemove'

export default function Farms() {
    return (
        <div className="subscription-page">
            <FarmAdd />
            <FarmUpdate />
            <FarmRemove />
        </div>
    )
}