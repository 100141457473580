import './Map.css'

import io  from 'socket.io-client'

import { 
    useEffect, 
    useState 
} from 'react'

import { 
    MapContainer, 
    TileLayer, 
} from 'react-leaflet'

import vehicle from '../icons/vehicle.png'
import vehicleFault from '../icons/vehicle_fault.png'
import structureNode from '../icons/structure_node.png'
import structureNodeFault from '../icons/structure_node_fault.png'

import MapIcon from '../components/MapIcon'
import LayersControl from '../components/LayersControl'

import { useAuthContext } from '../hooks/useAuthContext';

export default function Map() {
    const [iconData, setIconData] = useState('');

    const [statedInitd, setStatesInitd] = useState(false);
    const [iconStates, setIconStates] = useState('');
    const [farmStates, setFarmStates] = useState('');

    // lookup reference for custom icons
    const iconImages = {
        "vehicle": [vehicle, vehicleFault],
        "structure_node": [structureNode, structureNodeFault]
    }

    const {user} = useAuthContext();

    const initStates = (icons) => {
        // group icons by type and set key object properties
        setIconStates(icons.reduce((acc, obj) => {
            const key = obj["icon_type"];
            const icon_type = key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
        
            return { ...acc, [key]: {id: key, icon_type: icon_type, checked: true} };
        }, {}));

        // group farms by id and set key object properties
        setFarmStates(icons.reduce((acc, obj) => {
            const key = obj["parent_id"];
            return { ...acc, [key]: {id: key, checked: true}}
        }, {}))
    }

    // icon data socket connections
    useEffect(() => {
        if (user) {
            const iconSocket = io("/icons", {
                path: '/api/live-data',
                auth: { authorization: `Bearer ${user.token}` },
            })

            iconSocket.on("connect", () => {
                console.log("socket connection - icon data")
            });

            iconSocket.on('disconnect', () => {
                console.log("socket disconnection - icon data")
            })

            iconSocket.on("data", (data) => {
                setIconData(data);
                
                // on first data
                if (!statedInitd) {
                    initStates(data);
                    setStatesInitd(true);
                }
            });

            iconSocket.on("connect_error", (error) => {
                console.log(`socket error - ${error.message}`)
            });

            return () => {
                iconSocket.disconnect();

                iconSocket.off("connect");
                iconSocket.off("disconnect");
                iconSocket.off("data");
                iconSocket.off("connect_error");
            }
        }
    }, [user, statedInitd])

    return (
        <div className='map-container'>
            <LayersControl 
                iconStates={iconStates} 
                setIconStates={setIconStates}
                farmStates={farmStates}
                setFarmStates={setFarmStates}
            />
            <MapContainer center={[user.location.latitude, user.location.longitude]} zoom={14} scrollWheelZoom={true}>
                <TileLayer
                    attribution='Tiles &copy; Esri'
                    url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                />

                {(iconData && iconStates) && iconData.map((icon)=> {
                    return (
                        <MapIcon
                            key={icon._id} 
                            iconData={icon}
                            displayDevice={iconStates[icon.icon_type].checked}
                            displayFarm={farmStates[icon.parent_id].checked}
                            icon={iconImages[icon.icon_type][0]}
                            iconFault={iconImages[icon.icon_type][1]}
                        />
                    )
                })}
            </MapContainer>
        </div>
    )
}