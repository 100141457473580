import { useState } from 'react'
import { useRegister } from '../hooks/useRegister'
import { Link, useNavigate } from 'react-router-dom'

export default function Register() {
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [farmID, setFarmID] = useState('')
    const [farmToken, setFarmToken] = useState('')

    const { register, error, isLoading, success } = useRegister()

    const navigate = useNavigate();

    // send registration request
    const handleSubmit = async (e) => {
        e.preventDefault()

        const success = await register(email, username, password, passwordConfirm, farmID, farmToken)

        if (success) {
            navigate("/validate");
        }
    }

    return (
        <div className='login-page'>
            <div className='form-container'>
                <div className='information-container'>
                    <div className='company-logo'/>
                    <div className='company-name'>PAStech <span className='accent'>Rural</span></div>
                    <div className='description'>
                        Description about the application here
                    </div>
                    <a href='https://pastech.com.au/contact/' className='contact-button'>
                        Contact Us
                    </a>
                </div>
                <div className='entry-container'>
                    <div className='switch-message'>
                        Already have an account? <Link className='link' to='/login'>Log In</Link>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='welcome-message'>
                            <div className='text1'>Hello!</div>
                            <div className='text2'>Enter Your Details to Begin</div>
                        </div>

                        <input 
                            type='email'
                            id='email'
                            onChange={(e) => setEmail(e.target.value)} 
                            value={email}
                            placeholder="Email Address" 
                        />

                        <input 
                            type='text'
                            id='username'
                            onChange={(e) => setUsername(e.target.value)} 
                            value={username}
                            placeholder="Username" 
                        />

                        <input 
                            type='password'
                            id='password'
                            onChange={(e) => setPassword(e.target.value)} 
                            value={password}
                            placeholder="Password" 
                        />

                        <input 
                            type='password'
                            id='password'
                            onChange={(e) => setPasswordConfirm(e.target.value)} 
                            value={passwordConfirm}
                            placeholder="Confirm Password" 
                        />

                        <input 
                            type='text'
                            id='number'
                            onChange={(e) => setFarmID(e.target.value)} 
                            value={farmID}
                            placeholder="Farm ID" 
                        />

                        <input 
                            type='password'
                            id='token'
                            onChange={(e) => setFarmToken(e.target.value)} 
                            value={farmToken}
                            placeholder="Farm Token" 
                        />
                        {error && <div className='error-message'>{error}</div>}
                        {success && <div className='success-message'>{success}</div>}

                        <div className='buttons'>
                            <button className='submit-button' disabled={isLoading}>Sign Up</button>
                            <Link to='/validate'>Validate Email</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}