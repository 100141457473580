import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';

export default function DeviceUpdate() {
    const { user } = useAuthContext();

    const [farms, setFarms] = useState('');
    const [devices, setDevices] = useState('');

    const [farmId, setFarmId] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [deviceType, setDeviceType] = useState('');
    const [scanningInterval, setScanningInterval] = useState('');

    const [ipv4, setIpv4] = useState('');
    const [port, setPort] = useState('');

    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchFarms = async () => {
            const response = await fetch('/api/admin/get-farms', {
                method: 'POST',
                body: JSON.stringify({}),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if (!response.ok) {
                console.log(json.error)
            } if (response.ok) {
                setFarms(json);
            }
        }

        if (user) {
            fetchFarms()
        }
    }, [user])

    useEffect(() => {
        const fetchDevices = async () => {
            const response = await fetch('/api/admin/get-devices', {
                method: 'POST',
                body: JSON.stringify({
                    'parent_id': farmId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
    
            if (!response.ok) {
                setDevices([{'_id': json.error}])
                console.log(json.error)
            } if (response.ok) {
                setDevices(json)
            }
        }

        if (user && farmId) {
            fetchDevices()
        }
    }, [user, farmId])

    useEffect(() => {
        const fetchDevices = async () => {
            const response = await fetch('/api/admin/get-devices', {
                method: 'POST',
                body: JSON.stringify({
                    '_id': deviceId
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()
    
            if (!response.ok) {
                setDevices([{'_id': json.error}])
                console.log(json.error)
            } if (response.ok) {
                console.log(json)
                setScanningInterval(json[0].scanning_interval)
                setIpv4(json[0].ipv4)
                setPort(json[0].port)
            }
        }

        if (user && deviceId) {
            fetchDevices()
        }
    }, [user, deviceId])

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)
        const response = await fetch('/api/admin/update-devices', {
            method: 'POST',
            body: JSON.stringify({
                '_id': deviceId,
                'scanning_interval': scanningInterval,
                'type': deviceType,
                'ipv4': ipv4,
                'port': port
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
        const json = await response.json()

        if (!response.ok) {
            setMessage(json.error)
        } if (response.ok) {
            setMessage("Device Updated")
        }
        setIsLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <h1>Update Device</h1>
            <label>Farm</label>
            <select 
                id='farmSelect' 
                onChange={(e) => setFarmId(e.target.value)} 
            >
                <option value="">--Select Farm--</option>
                {farms && farms.map(farm => {
                    return <option key={farm._id} value={farm._id}>{farm.name}</option>
                })}
            </select>
            <label>Device</label>
            <select 
                id='deviceSelect' 
                onChange={(e) => {
                    setDeviceId(e.target.value)
                    setDeviceType(e.target.options[e.target.selectedIndex].getAttribute("device-type"));
                }} 
                disabled={!farmId}
            >
                <option value="">--Select Farm--</option>
                {devices && devices.map(device => {
                    if(device.type === 'breadcrumb') {
                        return <option key={device._id} value={device._id} device-type={device.type}>{device.type} - {device.ipv4}</option>
                    } else {
                        return <option key={device._id} value={device._id} device-type={device.type}>{device.type} - {device._id}</option>
                    }
                })}
            </select>
            <label>Scanning Interval</label>
            <input 
                id='scanningInterval'
                type='number' 
                disabled={!deviceId} 
                defaultValue={scanningInterval}
                onChange={(e) => setScanningInterval(e.target.value)} 
            />

            {(deviceType === 'breadcrumb') ? 
                <>
                    <label>IP address</label>
                    <input 
                        id='ipv4'
                        type='text' 
                        disabled={!deviceId} 
                        defaultValue={ipv4}
                        onChange={(e) => setIpv4(e.target.value)} 
                    />
                    <label>Port</label>
                    <input 
                        id='port'
                        type='number' 
                        disabled={!deviceId} 
                        defaultValue={port}
                        onChange={(e) => setPort(e.target.value)} 
                    />
                </>
                : ''}

            {message && <div className='message'>{message}</div>}

            <button disabled={isLoading}>Update Device</button>
        </form>
    )
}