import './Login.css'

import { useState } from 'react'
import { useLogin } from '../hooks/useLogin'
import { Link } from 'react-router-dom'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {login, error, isLoading} = useLogin()

    // send login request
    const handleSubmit = async (e) => {
        e.preventDefault()

        await login(email, password)
    }

    return (
        <div className='login-page'>
            <div className='form-container'>
                <div className='information-container'>
                    <div className='company-logo'/>
                    <div className='company-name'>PAStech <span className='accent'>Rural</span></div>
                    <div className='description'>
                        Description about the application here
                    </div>
                    <a href='https://pastech.com.au/contact/' className='contact-button'>
                        Contact Us
                    </a>
                </div>
                <div className='entry-container'>
                    <div className='switch-message'>
                        Don't have an account? <Link className='link' to='/register'>Sign Up</Link>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='welcome-message'>
                            <div className='text1'>Hello Again!</div>
                            <div className='text2'>Welcome Back</div>
                        </div>
                        
                        <input 
                            type='email'
                            id='email'
                            onChange={(e) => setEmail(e.target.value)} 
                            value={email}
                            placeholder="Email Address" 
                        />

                        <input 
                            type='password'
                            id='password'
                            onChange={(e) => setPassword(e.target.value)} 
                            value={password}
                            placeholder="Password"
                        />

                        {error && <div className='error-message'>{error}</div>}

                        <div className='buttons'>
                            <button className='submit-button' disabled={isLoading}>Log In</button>
                            <Link to='/send-password-reset'>Forgot Password?</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}