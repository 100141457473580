import './Login.css'

import { Link } from 'react-router-dom'

import { useState } from 'react'

export default function ResendValidate(params) {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [email, setEmail] = useState();

    // post email to send validate to
    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true);
        setError(null);
        setSuccess(null);

        const response = await fetch('/api/user/resend-validate', {
            method: 'POST', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({email: email})
        })

        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
            console.log(json.error)
        }
        if (response.ok) {
            setIsLoading(false);
            setSuccess(json.message);
        }
    }

    return (
        <div className='login-page'>
            <div className='form-container'>
                <div className='information-container'>
                    <div className='company-logo'/>
                    <div className='company-name'>PAStech <span className='accent'>Rural</span></div>
                    <div className='description'>
                        Description about the application here
                    </div>
                    <a href='https://pastech.com.au/contact/' className='contact-button'>
                        Contact Us
                    </a>
                </div>
                <div className='entry-container'>
                    <div className='switch-message'>
                    Already have an account? <Link className='link' to='/login'>Log In</Link>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className='welcome-message'>
                            <div className='text1'>Resend Email</div>
                            <div className='text2'>Enter email attached to account.</div>
                        </div>
                        
                        <input 
                            type='email'
                            id='email'
                            placeholder="Email Address" 
                            onChange={(e) => setEmail(e.target.value)} 
                        />

                        {error && <div className='error-message'>{error}</div>}
                        {success && <div className='success-message'>{success}</div>}

                        <div className='buttons'>
                            <button className='submit-button' disabled={isLoading}>Resend Email</button>
                            <Link to='/validate'>Validate Email</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}