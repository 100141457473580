import './Devices.css'

import DeviceAdd from '../components/DeviceAdd'
import DeviceUpdate from '../components/DeviceUpdate'
import DeviceRemove from '../components/DeviceRemove'

export default function Devices() {
    return (
        <div className="subscription-page">
            <DeviceAdd />
            <DeviceUpdate />
            <DeviceRemove />
        </div>
    )
}