import './Popup.css'
import { FaArrowLeft } from 'react-icons/fa6'

export default function Popup(props) {
    return (props.trigger) ? (
        <div className="popup">
            <div className="popup-inner">
                <div onClick={() => props.setTrigger(false)} className="close-button">
                    <div className='x' />
                </div>
                {props.children}
            </div>
        </div>
    ) : "";
}