import './Login.css'

import { Link, useParams } from 'react-router-dom'

import { useState, useEffect } from 'react'

export default function Validate(params) {
    const {id} = useParams()

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [token, setToken] = useState();

    const validateEmail = async (token) => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        if (token !== 'validate') { // removed for if no id provided
            const response = await fetch('/api/user/validate', {
                method: 'POST', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({token: token})
            })

            const json = await response.json();

            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
                console.log(json.error)
            }
            if (response.ok) {
                setIsLoading(false)
                setSuccess("Validated Email");
            }
        }
    }

    // send token to validate email
    const handleSubmit = async (e) => {
        e.preventDefault()
        validateEmail(token);
    }

    // send token from URL on load
    useEffect(() => {
        validateEmail(id)
    }, [id])

    return (
        <div className='login-page'>
            <div className='form-container'>
                <div className='information-container'>
                    <div className='company-logo'/>
                    <div className='company-name'>PAStech <span className='accent'>Rural</span></div>
                    <div className='description'>
                        Description about the application here
                    </div>
                    <a href='https://pastech.com.au/contact/' className='contact-button'>
                        Contact Us
                    </a>
                </div>
                <div className='entry-container'>
                    <div className='switch-message'>
                    Already have an account? <Link className='link' to='/login'>Log In</Link>
                    </div>

                    {(id === 'validate' || error) ? 
                    <form onSubmit={handleSubmit}>
                        <div className='welcome-message'>
                            <div className='text1'>Validate Email</div>
                            <div className='text2'>Check your email for instruction.</div>
                        </div>
                        
                        <input 
                            type='token'
                            id='token'
                            placeholder="Validation Token" 
                            onChange={(e) => setToken(e.target.value)} 
                        />

                        {error && <div className='error-message'>{error}</div>}
                        {success && <div className='success-message'>{success}</div>}

                        <div className='buttons'>
                            <button className='submit-button' disabled={isLoading}>Validate Email</button>
                            <Link to='/resend-validate'>Resend Email</Link>
                        </div>
                    </form> : 
                    <div>Thankyou for validating your email. Click <Link className='link' to='/login'>here</Link> to login.</div>
                    }
                </div>
            </div>
        </div>
    )
}