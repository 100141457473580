import './Subscription.css'

import SubscriptionAdd from '../components/SubscriptionAdd'
import SubscriptionUpdate from '../components/SubscriptionUpdate'
import SubscriptionRemove from '../components/SubscriptionRemove'

export default function Subscription() {
    return (
        <div className="subscription-page">
            <SubscriptionAdd />
            <SubscriptionUpdate />
            <SubscriptionRemove />
        </div>
    )
}